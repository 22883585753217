import React from 'react';
import { Text } from 'wix-ui-tpa/Text';
import { classes, st } from './SectionHeader.st.css';
import { AccessibilityHtmlTags } from '../../../../utils/accessibility/constants';
import { Divider } from 'wix-ui-tpa/Divider';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { DataHooks } from './constants';

export type SectionHeaderProps = {
  title: string;
  suffix?: React.ReactNode;
};

const SectionHeader: React.FC<SectionHeaderProps> = ({ title, suffix }) => {
  const { isMobile } = useEnvironment();

  return (
    <>
      <div className={st(classes.root, { isMobile })}>
        <Text
          data-hook={DataHooks.Title}
          className={classes.title}
          tagName={AccessibilityHtmlTags.SecondaryHeading}
        >
          {title}
        </Text>
        {suffix}
      </div>
      <Divider className={classes.divider} data-hook={DataHooks.Divider} />
    </>
  );
};

export default SectionHeader;
